
import { defineComponent, onMounted } from 'vue';
import { useStore } from 'vuex';
import MainLayout from '@/layout/MainLayout.vue';

export default defineComponent({
  name: 'Home',
  components: { MainLayout },
  setup() {
    const store = useStore();

    onMounted(() => {
      store.dispatch('stats/GET_DATA');
    });
  },
});

