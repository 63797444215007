<template lang="pug">
Header
slot
</template>

<script>
import { defineComponent } from 'vue';
import Header from '../components/Header/index.vue';

export default defineComponent({
  name: 'MainLayout',
  components: { Header },
});
</script>

<style lang="stylus"></style>
