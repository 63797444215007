<template lang="pug">
router-link.header-logo(to="/")
  img(src="@/assets/images/main-logo.svg")
  div
    span Сервис полевой работы
    span Государственная налоговая служба при Министерстве финансов Кыргызской Республики
</template>

<script>
export default {
  name: 'Logo',
};
</script>

<style lang="stylus">
.header-logo
  display flex
  align-items center
  margin-right auto
  text-decoration none
  color inherit
  img
    width 9.3rem
  div
    display flex
    flex-direction column
    align-items flex-start
    margin-left 2.5rem
  span:first-child
    font-size 2.25rem
    text-transform uppercase
    font-weight 500
    margin-bottom .2em
  span:last-child
    display block
    font-family $font.secondary
    font-weight 600
    font-size 1rem
    width 31rem
    color #808080
</style>
