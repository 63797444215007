<template lang="pug">
.main-header
  Logo
  Tabs(:tabs="tabs" :activeTabIndex="currentTab")
  Exit
</template>

<script type="ts">
import {
  computed, defineComponent,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import Tabs from '@/components/UI/Tabs/index.vue';
import Logo from '@/components/Header/Logo.vue';
import Exit from '@/components/Header/Exit.vue';
import store from '@/store';

export default defineComponent({
  components: {
    Exit, Tabs, Logo,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const tabs = computed(() => [
      {
        name: 'Карта',
        get active() {
          return route.path === '/';
        },
        onClick() { router.push('/'); },
      },
      {
        name: 'Реестр',
        get active() {
          return route.path.includes('/registry');
        },
        onClick() { router.push('/registry/trade-objects'); },
      },
      {
        name: 'Рынки/Торговые центры',
        get active() {
          return route.path.includes('/markets');
        },
        onClick() { router.push('/markets-and-malls'); },
      },
      {
        name: 'Отчёты',
        get active() {
          return route.path.includes('/reports');
        },
        onClick() { router.push('/reports'); },
      },
    ].filter((a) => a));
    const currentTab = computed(() => {
      const index = tabs.value.findIndex((item) => item.active);
      return index;
    });

    return {
      tabs, currentTab,
    };
  },
});

</script>

<style lang="stylus">
.main-header
  padding 0 2.2rem 0 11rem
  height 13rem
  display flex
  align-items center
  box-shadow: .2rem .2rem 1.2rem rgba(20, 29, 37, 0.2);
  &__btn
    font-size 2rem
    padding 1.2rem 10rem
  &__ttl
    margin 0 auto
    text-transform uppercase
    font-size 2.2rem
    font-weight 500
</style>
