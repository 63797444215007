
import { defineComponent, computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { translateRoles } from '@/utils';

export default defineComponent({
  name: 'Exit',
  setup() {
    const router = useRouter();
    const store = useStore();
    const exit = async () => {
      await store.dispatch('auth/LOGOUT', router);
      await router.replace({ name: 'Login' });
      // window.router = router;
    };

    const fullLabel = computed(() => `${store.state.auth.userInfo?.firstName || ''} ${store.state.auth.userInfo?.lastName || ''} | ${translateRoles(store.state.auth.userInfo?.roles[0])}`);
    const isAccessMenuOpen = ref(false);

    const toggleMenu = () => {
      if (isAccessMenuOpen.value) isAccessMenuOpen.value = false;
      document.removeEventListener('mouseup', toggleMenu);
    };

    const open = () => {
      isAccessMenuOpen.value = true;
      document.addEventListener('mouseup', toggleMenu);
    };

    return {
      exit, fullLabel, isAccessMenuOpen, open, toggleMenu,
    };
  },
});
